import Context from './context/Context';
import MosCoreServicesFactory from 'mw-mos-core-services/MosCoreServicesFactory';
import AuthnzService from './authentication/AuthnzService';
import SLAService from './sla/SLAService';
import MatlabOnline from './home';
import configUtils from './utils/configUtils';
import {setLogLevel} from './utils/utils';
import defaultConfig from './homeConfig';
import logger from 'mw-log/Log';

let app = {};
let _logger = logger.createLogger("mos-home");

configUtils.load(defaultConfig, 'matlabOnlineConfig', _logger).then(async configuration => {
  let rootNodeId = 'root';

  setLogLevel(configuration.mode, _logger);

  let context = new Context(configuration, _logger);
  let authnzService = new AuthnzService(context, configuration, _logger);
  let slaService = new SLAService(context, configuration, _logger);
  const serviceEndpoint = new URL('../', window.location.href).href;
  let resourceService = await MosCoreServicesFactory.getResourceService({serviceEndpoint});
  let gatewayService = await MosCoreServicesFactory.getGatewayService({serviceEndpoint});
  let resourceProxyService = await MosCoreServicesFactory.getResourceProxyService({serviceEndpoint});

  let matlabOnline = new MatlabOnline(rootNodeId, resourceService, gatewayService, authnzService, slaService, resourceProxyService, configuration, _logger);

  app.configuration = configuration;
  app.context = context;
  app.resourceService = resourceService;
  app.gatewayService = gatewayService;
  app.authnzService = authnzService;
  app.slaService = slaService;
  app.resourceProxyService = resourceProxyService;
  app.matlabOnline = matlabOnline;
  matlabOnline.start().then(function () {
    _logger.log('Started');
  });
});

export default app;
