let pageKey = 'matlabonlineserver/pageContext';
let pageContext;

function getPageContext () {
  return pageContext;
}

function setPageContextValue (key, newValue) {
  let oldValue = pageContext[key];
  pageContext[key] = newValue;
  pageContext.lastEdit = new Date();
  window.sessionStorage.setItem(pageKey, JSON.stringify(pageContext));
  return oldValue;
}

function getPageContextValue (key, defaultValue) {
  let value = pageContext[key];
  if (value === undefined) {
    value = defaultValue;
  }
  return value;
}

// decrement the count so that cloned pages can be identified
window.addEventListener('beforeunload', () => {
  try {
    pageContext = JSON.parse(window.sessionStorage.getItem(pageKey));
    if (pageContext) {
      pageContext.count = Math.max(0, pageContext.count - 1);
      //            console.log("Decrementing page context count: " + JSON.stringify(pageContext));
      window.sessionStorage.setItem(pageKey, JSON.stringify(pageContext));
    }
  } catch (ignore) {
  }
});

try {
  pageContext = JSON.parse(window.sessionStorage.getItem(pageKey));
//    console.log("Initial page context: " + JSON.stringify(pageContext));
} catch (ignore) {
}

// if the counter is non-zero, then this is a cloned page and the storage should be reset
if (!pageContext || pageContext.count !== 0 || pageContext.version !== 1) {
  let idBytes = new Uint32Array(1);

  let crypto = window.crypto || window.msCrypto; // ie 11 compatibility
  crypto.getRandomValues(idBytes);

  pageContext = {
    version: 1,
    id: btoa(idBytes),
    page: document.location.toString(),
    count: 1,
    lastEdit: new Date()
  };
//    console.log("Invalid page context detected, creating new one: " +
//            JSON.stringify(pageContext));
} else {
  pageContext.count = 1;
}
sessionStorage.setItem(pageKey, JSON.stringify(pageContext));

export default {
  getAll: getPageContext,
  getValue: getPageContextValue,
  setValue: setPageContextValue
};
