import {objectDeepAssign} from './utils';

function getQueryConfig () {
  let configuration = {};

  // use query parameters to override config
  if (document.location.search.length > 0) {
    let parts = document.location.search.substr(1).split('&');
    parts.forEach(part => {
      let i = part.indexOf('=');
      if (i > 0) {
        let key = part.substring(0, i);
        let value = part.substring(i + 1);

        let keyParts = key.split('.');
        let c = configuration;
        keyParts.forEach((part, j) => {
          if (j === keyParts.length - 1) {
            c[part] = value;
          } else {
            if (c[part] === undefined) {
              c[part] = {};
            }
            c = c[part];
          }
        });
      } else {
        configuration[part] = true;
      }
    });
  }

  return configuration;
}

async function load (config, overrideFilePrefix,logger) {
  let devFile = document.location.pathname.endsWith('-dev.html');
  let postfix = devFile ? 'Dev.json' : 'Release.json';
  let overrideFile = overrideFilePrefix + postfix;

  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  try {
    // await response of fetch call
    let httpResponse = await fetch(overrideFile,
      {
        method: 'GET',
        mode: 'cors',
        headers: headers,
        credentials: 'include'
      });

    let response = await httpResponse.json();
    config = objectDeepAssign(config, response);
  } catch (ex) {
    logger.warn('No override configuration');
  }

  config = objectDeepAssign(config, getQueryConfig());
  logger.log('Configuration', config);
  return config;
}

export default {
  load: load
};
