export default {
  faultMessages: {
    'request.invalid_request': 'Invalid request',
    'service_failure.request.lease_failed': 'Unable to connect to MATLAB. Please try again later.',
    'service_failure.request.mapping_failed': 'Mapping request failed',
    'service_failure.request.keepalive_failed': 'Resource keepAlive failed',
    'service_failure.request.existing_in_use_mapping': 'MATLAB has been transferred to be used by another session. You have been disconnected because you opened MATLAB Online in another window.',
    'service_failure.request.no_resources_available': 'There are no MATLABs that are available to be used. Please try again later.',
    'request.cancelled': 'Request is cancelled',
    'service_failure.not_found': 'Requested service is not found',
    'service_failure': 'There seems to be an error in service.',
    'service_failure.authnz': 'Unable to communicate with the authentication service.',
    'service_failure.request.not_found': 'Something is not found while processing this request. Please try again later.',
    'service_failure.request.io': 'I/O error occurred while processing this request',
    'request.unauthorized': 'The user is unauthorized to make the request',
    'request.storage_setup_failed': 'There seems to be an error while setting up storage for MATLAB.',
    'session.session_timeout_message': 'Your MATLAB session has timed out. Click Resume to pick up where you left off',
    'session.session_exists_message': 'There is an existing MATLAB session that is being used from another window.',
    'session.session_disconnect_question': 'There may be an existing MATLAB session currently running. ' +
      'Only one session is able to access the MATLAB server at a time. ' +
      'Do you want to disconnect the other session?'
  }
};
