/* Copyright 2021 The MathWorks, Inc. */
define([
    'mw-ui-themes/ThemeEnum'],
function (ThemeEnum) {
    // Creating a variable to store the setting service injection. We are doing
    // Object.freeze so can't edit variable after instance is created
    let settingService;

    /**
     * @class UIThemeManager
     * @classdesc UIThemeManager is a singleton Class.<br>This class is responsible for setting up the requested theme class on body or an optionally specified DOM node.
     */
    class UIThemeManager {
        constructor () {
            this.themeClass = {
                'light': 'mw-theme-light',
                'dark': 'mw-theme-dark'
            };
            this.themeName = {
                'mw-theme-light': ThemeEnum.LIGHT,
                'mw-theme-dark': ThemeEnum.DARK
            };
            if (window.matchMedia) {
                // Fix for g3013917 - window.matchMedia does not exists in fake browser test causing test issues in downstream teams hence adding check
                this.windowMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            }
            this._mediaQueryEventHandler = this._mediaQueryEventHandler.bind(this);
        }

        /**
         * @property {Boolean} useSystemMode
         * This property is used to set the theme based on OS mode.<br>
         * true - Change in OS theme will cause a change in theme in our application.<br>
         * false - Change in OS theme will NOT cause a change in theme in our application.
         * @example
         * UIThemeManager.useSystemMode = true;
         */
        set useSystemMode (value) {
            if (value) {
                this._setThemeBySystemMode();
            } else {
                this._removeSystemMode();
            }
        }

        /**
         * @property {Object} useSettings
         * This property is used to set the theme based on SettingService object.<br>
         * The property will check for the setting value under MATLAB -> Appearance -> MATLAB Theme. If the value is<br>
         * System - Change in OS theme will cause a change in theme in our application.<br>
         * Light - Application will be in light theme. Change in OS theme will NOT cause change in theme in our application.<br>
         * Dark - Application will be in dark theme. Change in OS theme will NOT cause change in theme in our application.
         * @example
         * UIThemeManager.useSettings = SettingService;
         */
        set useSettings (value) {
            if (value) {
                settingService = value;
                settingService.getSetting(['matlab', 'appearance'], 'MATLABTheme').then((settingValue) => {
                    this._settingsValueHandler(settingValue.value);
                });

                // attach a settings changed callback
                settingService.listenTo(['matlab', 'appearance'], 'MATLABTheme', (event) => {
                    this._settingsValueHandler(event.newValue);
                });
            }
        }

        _settingsValueHandler (value) {
            let oldTheme;
            switch (value) {
                case 'System':
                    this.useSystemMode = true;
                    break;
                case 'Light':
                    this.useSystemMode = false;
                    oldTheme = this.getTheme();
                    this.setTheme(ThemeEnum.LIGHT);
                    this._emitEvent(oldTheme, ThemeEnum.LIGHT);
                    break;
                case 'Dark':
                    this.useSystemMode = false;
                    oldTheme = this.getTheme();
                    this.setTheme(ThemeEnum.DARK);
                    this._emitEvent(oldTheme, ThemeEnum.DARK);
                    break;
            }
        }

        _setThemeBySystemMode () {
            let oldTheme = this.getTheme();
            if (this.windowMediaQuery.matches) {
                this.setTheme(ThemeEnum.DARK);
                this._emitEvent(oldTheme, ThemeEnum.DARK);
            } else {
                this.setTheme(ThemeEnum.LIGHT);
                this._emitEvent(oldTheme, ThemeEnum.LIGHT);
            }
            if (this.windowMediaQuery) {
                this.windowMediaQuery.addEventListener('change', this._mediaQueryEventHandler);
            }
        }

        _removeSystemMode () {
            this.windowMediaQuery.removeEventListener('change', this._mediaQueryEventHandler);
        }

        _mediaQueryEventHandler (e) {
            let oldTheme = this.getTheme();
            if (e.matches) {
                this.setTheme(ThemeEnum.DARK);
                this._emitEvent(oldTheme, ThemeEnum.DARK);
            } else {
                this.setTheme(ThemeEnum.LIGHT);
                this._emitEvent(oldTheme, ThemeEnum.LIGHT);
            }
        }

        _emitEvent (oldThemeName, newThemeName) {
            let eventData = { oldTheme: oldThemeName, newTheme: newThemeName };
            let eventObj = new CustomEvent('themechanged', {
                detail: eventData
            });
            /**
             * This event is triggred when ever a theme is changed.
             * @event UIThemeManager#themechanged
             * @property {Object} evt
             * @property {String} evt.detail.oldTheme - the old theme name
             * @property {String} evt.detail.newTheme - the current theme name
             *
             * @example
             * node.addEventListener('themechanged', function (evt) {
             *      // do something
             * });
             */
            document.body.dispatchEvent(eventObj);

            // Update the setting to reflect the new theme
            if (settingService) {
                const newThemeNameSentenceCase = newThemeName.charAt(0).toUpperCase() + newThemeName.slice(1);
                settingService.setSetting(['matlab', 'appearance'], 'CurrentTheme', newThemeNameSentenceCase);
            }
        }

        /**
         * Sets the requested themes class selector on requested domNode by default the domNode is body
         * @param {string} themeName - refer the values from ThemeEnum
         * @param {Object} [domNode=body] - domNode on which the theme class need to be added
         * @example
         * var ThemeEnum = require('mw-ui-themes/ThemeEnum');
         * var UIThemeManager = require('mw-ui-themes/UIThemeManager');
         * UIThemeManager.setTheme(ThemeEnum.LIGHT) // set light theme class on body element
         *
         * var node = document.getElementById('figure')
         * UIThemeManager.setTheme(ThemeEnum.DARK,node) // set dark theme class on specified node
         */
        setTheme (themeName, domNode) {
            // checking if the requested theme is light or dark
            if (Object.keys(this.themeClass).includes(themeName)) {
                let node = domNode || document.getElementsByTagName('body')[0];
                if (this._isHTMLElement(node)) {
                    for (let key in this.themeClass) {
                        if (node.classList.contains(this.themeClass[key])) {
                            // remove existing theme-class
                            node.classList.remove(this.themeClass[key]);
                        }
                    }
                    node.style.colorScheme = themeName;
                    node.classList.add(this.themeClass[themeName]);
                }
            } else {
                throw new Error('The requested theme is not supported');
            }
        }

        _isHTMLElement (node) {
            // checking if given node is a HTMLElement
            // the content of iframe is not a HTML element because the context of the window is changed hence an additional check
            // to check if the passed content is content of iframe
            if (node && (node instanceof HTMLElement || (node.ownerDocument && node.ownerDocument.defaultView && node.ownerDocument.defaultView.Element && node instanceof node.ownerDocument.defaultView.Element))) {
                return true;
            } else {
                return false;
            }
        }

        /**
         * Return the exisitng theme name on the specified domNode or by default body element
         * @param {Object} [domNode=body]
         * @returns ThemeName on existing domNode
         *
         * @example
         * var UIThemeManager = require('mw-ui-themes/UIThemeManager')
         * var currentTheme = UIThemeManager.getTheme(); // returns existing theme name on body
         *
         * var node = document.getElementById('figure');
         * var currentTheme = UIThemeManager.getTheme(node); // returns existing theme name on specified domNode
         */
        getTheme (domNode) {
            let node = domNode || document.getElementsByTagName('body')[0];
            let theme = window.getComputedStyle(node).getPropertyValue('--mw-themeName').trim();
            return theme;
        }
    }

    var UIThemeManagerInstance = new UIThemeManager();
    Object.freeze(UIThemeManagerInstance);

    return UIThemeManagerInstance;
});
