 
  //noinspection BadExpressionStatementJS,UnterminatedStatementJS
 define({ 
         ok: "确定",
         cancel: "取消",
         switchPanes: "切换窗口",
         close: "关闭",
         yes: "是",
         no: "否",
         help: "帮助",
         submit: "提交",
         save: "保存",
         find: "查找",
         create: "创建",
         dontSave: "不保存",
         apply: "应用",
         revert: "还原"
     });

