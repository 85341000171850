/**
 * @copyright Copyright 2017 MathWorks, Inc.
 */
define([
    "dojo/_base/window",
    "dojo/_base/kernel",
    "dojo/html",
    "dojo/window",
    "./IconAndMessageWidget",
    "./BannerWidget",
    "./ProgressBar",
    "mw-dialogs/Dialogs",
    "mw-dialogs/ButtonEnum"
], function(baseWin, kernel, html, win, IconAndMessageWidget, BannerWidget, ProgressBar, Dialogs, ButtonEnum) { // eslint-disable-line max-len
    /**
     * Notifications is a module with convenience methods to notification dialogs and widgets.
     * @exports Notifications
     */
    var _notifications = {

        /**
         * displayAlertDialog method will create an alert dialog inside the web app.
         * The default alert dialog will have a message, title, error icon and an OK button.
         * Users can configure the dialog further.
         *
         * @static
         *
         * @param {string} title - The title to be shown on the dialog.
         *
         * @param {string|domNode} message - The message string to be shown inside the dialog.
         * See {@link module:IconAndMessageWidget} for more details
         *
         * @param {object} options - This object can be used to configure alert dialogs.
         * Currently supported options include following:
         * @param {string} options.icon="error" The icon for the dialog can be preset icons
         * from {@link module:IconAndMessageWidget} or an image data URI.
         * @param {string} options.customIconAltText="" The alt-text of non-preset icons for the dialog
         * @param {boolean} options.modal=true determines if the dialog is modal
         * to the application or not.
         * @param {function} options.closeCallback function to execute when the
         * alert is closed by the user.
         * @param {string} options.data-test-id="AlertDialog" test ID string for testing.
         *
         * @param {String | number} options.specifiedFocusItem -
         * If string is supplied, it corresponds to the id of the domNode that determines which
         * item in the dialog content area will have focus when the dialog shows up.
         * If number is supplied, the only acceptable value is 1 which corresponds to the OK
         * button receiving default focus.
         * If this option is not specified or the specifiedFocusItem couldn't be found, the first
         * focusable element in the dialog will get focus when the dialog shows up.
         *
         * @return {object} dialog - return modal or non-modal dialog widget. @mw-dialogs/Dialog
         *
         * @example <caption>Basic alert dialog</caption>
         * Notifications.displayAlertDialog("Invalid File Entered",
         *                                  "You entered an invalid file name. Please Check");
         * // Shows an alert dialog with error icon and OK button.
         *
         * @example <caption>Basic alert dialog with different Icon</caption>
         * var options = {
         *     icon: "info"
         * }
         * Notifications.displayAlertDialog("Files Uploaded",
         *               "Your files have now been uploaded to the MATLAB cloud!", options);
         * // Shows an alert dialog with information icon and OK button.
         *
         */
        displayAlertDialog: function(title, message, userOptions) {
            var dialogName = "AlertDialog";
            userOptions = userOptions || {};
            // Create icon and message widget
            var im = this._createIconAndMessageWidget(message, "error", userOptions);
            this._setupIconAndMessageWidget(im);

            //Create Buttons
            var button = [{
                type: ButtonEnum.OK.type,
                label: ButtonEnum.OK.label,
                "data-test-id": dialogName + "Button1"
            }];

            // Setup callback data and user callback (if any)
            var closeFunctionToEval = userOptions.closeCallback ? userOptions.closeCallback : function() {}; // eslint-disable-line max-len
            var callbackData = {
                eventName: dialogName + "Closed",
                dialogTitle: title,
                response: 1, // 1 because there is always one button - OK!
                buttonText: button[0].label
            };
            var dlg = Dialogs.createDialog({
                title: title,
                content: im,
                dialogType: (userOptions.modal === false) ? Dialogs.MODELESS : Dialogs.MODAL,
                buttons: button,
                draggable: true,
                specifiedFocusItem: this._parseAndGetSpecifiedFocusedItem(userOptions, button),
                "data-test-id": userOptions["data-test-id"] ? userOptions["data-test-id"] : dialogName, // eslint-disable-line max-len
                "className": "mw" + dialogName
            });
            // Whenever dialog closes, callback function will be executed.
            dlg.on("close", function() {
                closeFunctionToEval(callbackData);
            });
            // This will close the dialog whenever you click on the OK button.
            dlg.addButtonEventListener(ButtonEnum.OK, null, true);
            return dlg;
        },

        /**
         * @private
         */
        _createIconAndMessageWidget: function(message, defaultIcon, userOptions) {
            var iconData = userOptions.icon ? userOptions.icon : defaultIcon;
            var customIconAltText = userOptions.customIconAltText || "";
            return new IconAndMessageWidget({
                message: message,
                icon: iconData,
                customIconAltText: customIconAltText
            });
        },

        /**
         * @private
         */
        _setupIconAndMessageWidget: function(im) {
            var ws = win.getBox();
            // max height ranges between 80 and 300
            var maxHeight = Math.max(80, Math.min(0.4 * ws.h, 300));
            im.messageNode.style.maxHeight = maxHeight + "px";
        },
        /* In a case where too long text is used for a button,
            this method will remove maxWidth restriction on
            .mwDialogBody so that it will resize itself to width
            of the dialog.
        */
        _resizeToFitOversizeButtons: function(dlg) {
            if (parseInt(window.getComputedStyle(dlg.domNode).width) > 400) {
                dlg.domNode.querySelector('.mwDialogBody').style.maxWidth = "none";
            }
        },

        /**
         * @private
         * @param {Object} userOptions
         * @param {Array of ButtonEnum} buttons
         * @returns {String | ButtonEnum | undefined} specifiedFocusItem arg to pass to mw-dialogs/Dialog
         */
        _parseAndGetSpecifiedFocusedItem: function(userOptions, buttons) {
            if (!isNaN(userOptions.specifiedFocusItem)) {
                return buttons[userOptions.specifiedFocusItem - 1];
            } else {
                return userOptions.specifiedFocusItem;
            }
        },


        /**
         * displayConfirmDialog method will create an confirmation dialog inside the web app.
         * The default confirm dialog will have a message, title, confirm icon and
         * OK-Cancel buttons. Users can configure the dialog further.
         *
         * @static
         *
         * @param {string} title - The title to be shown on dialog.
         *
         * @param {string|domNode} message - The message string to be shown inside the dialog.
         * See {@link module:IconAndMessageWidget} for more details
         *
         * @param {object} options - This object can be used to add more properties to the dialog.
         * Currently supported options include following:
         * @Param {string[]} options.buttonText="Ok","Cancel" - The display text on the buttons
         *  to be created. should be between 1 to 5 buttons
         * @param {string} options.icon="question" - The icon for the dialog can be preset icons
         *  from {@link module:IconAndMessageWidget} or an image data URI.
         * @param {string} options.customIconAltText="" The alt-text of non-preset icons for the dialog
         * @param {number} options.defaultAcceptButton=1 - Button index 1,2,3,4 or 5 to determine
         *  the default button to be selected and default option for the user when they hit enter.
         * @param {number} options.defaultCancelButton=2/5 - Button index 1,2,3,4 or 5 to determine
         *  the default button when the user clicks close or uses escape key on the dialog.
         * @param {function} options.closeCallback - function to execute when the dialog is closed
         *  by the user.
         * @param {string} options.data-test-id="ConfirmDialog" - Test ID string for testing.
         * @param {String | number} options.specifiedFocusItem -
         * If string is supplied, it corresponds to the id of the domNode that determines which
         * item in the dialog content area will have focus when the dialog shows up.
         * If number is supplied, the acceptable values are 1,2,3,4 or 5 which corresponds to the
         * respective button to receive default focus.
         * If this option is not specified or the specifiedFocusItem couldn't be found, the first
         * focusable element in the dialog will get focus when the dialog shows up.
         * @return {object} dialog - return the modal dialog widget. @mw-dialogs/Dialog
         *
         * @example <caption>Basic confirm dialog</caption>
         * var options = {
         *     closeCallback: function(e) {
         *         if (e.response === 1) {
         *             //delete file
         *         }
         *     }
         * }
         * Notifications.displayConfirmDialog("Confirm File Delete",
         *                                    "Do you want to delete the file?", options);
         * // Shows an dialog with the above question with two buttons. On clicking OK or Cancel
         * // the above function is executed.
         *
         * @example <caption>Confirm dialog with three buttons</caption>
         * var options = {
         *     buttonText: ["Save", "Don't Save", "Cancel"],
         *     closeCallback: function(e) {// do something}
         * }
         * Notifications.displayConfirmDialog("Save File", "Do you want save the file?", options);
         * // Shows an dialog with the above question with three buttons.
         */
        displayConfirmDialog: function(title, message, userOptions) {
            var dialogName = "ConfirmDialog";
            userOptions = userOptions || {};
            // Create icon and message widget
            var im = this._createIconAndMessageWidget(message, "question", userOptions);
            this._setupIconAndMessageWidget(im);
            //defining button types to use
            var buttons = [];
            var buttontypes = [
                ButtonEnum.TYPE.DO_IT,
                ButtonEnum.TYPE.DONT_DO_IT,
                ButtonEnum.TYPE.CANCEL,
                ButtonEnum.TYPE.APPLY,
                ButtonEnum.TYPE.REVERT
            ];
            /*If user specifies button names and
            if there is atleast one button specified */
            if (userOptions.buttonText && userOptions.buttonText.length >= 1) {
                //Only take first 5 buttons to show if user has more than 5 buttons specified
                for (var i = 0; i < userOptions.buttonText.length && i < 5; i++) {
                    buttons.push({
                        type: buttontypes[i],
                        label: userOptions.buttonText[i],
                        "data-test-id": dialogName + "Button" + (i + 1)
                    });
                }
            } else {
                //use default buttons if user does not specify any buttons
                buttons = [{
                    type: ButtonEnum.TYPE.DO_IT,
                    label: ButtonEnum.OK.label,
                    "data-test-id": dialogName + "Button1"
                }, {
                    type: ButtonEnum.TYPE.CANCEL,
                    label: ButtonEnum.CANCEL.label,
                    "data-test-id": dialogName + "Button2"
                }];
            }
            // Setup callback data and user callback
            var closeFunctionToEval = userOptions.closeCallback ? userOptions.closeCallback : function() {}; // eslint-disable-line max-len
            var callbackData = {
                eventName: dialogName + "Closed",
                dialogTitle: title,
                response: 1, // 1 because that is the default
                buttonText: buttons[0].label
            };
            /* Setup default close button - Pick last button if not specified
            or is out of range else use the one user specified*/
            var closeButton = (userOptions.defaultCancelButton <= buttons.length) ? userOptions.defaultCancelButton : buttons.length; // eslint-disable-line max-len
            var closeButtonLabel = buttons[closeButton - 1].label;

            var dlg = Dialogs.createDialog({
                title: title,
                dialogType: Dialogs.MODAL,
                content: im,
                buttons: buttons,
                draggable: true,
                "className": "mw" + dialogName,
                "data-test-id": userOptions["data-test-id"] ? userOptions["data-test-id"] : dialogName, // eslint-disable-line max-len
                specifiedFocusItem: this._parseAndGetSpecifiedFocusedItem(userOptions, buttons),
                //user selected button or first button in the list
                defaultActionButton: (userOptions.defaultAcceptButton <= buttons.length) ? buttons[userOptions.defaultAcceptButton - 1] : buttons[0] // eslint-disable-line max-len
            });

            dlg.on('close', function() {
                callbackData.response = closeButton;
                callbackData.buttonText = closeButtonLabel;
                closeFunctionToEval(callbackData);
            });
            //add event listener to each button
            buttons.forEach(function(eachButton, index) {
                dlg.addButtonEventListener(eachButton, function() {
                    closeButton = index + 1;
                    closeButtonLabel = eachButton.label;
                }, true);
            });
            this._resizeToFitOversizeButtons(dlg);
            return dlg;
        },
        /**
         * displayProgressDialog will display a modal progress bar dialog inside the web app.
         *
         * @static
         *
         * @param {string} title - The title to be shown on dialog.
         *
         * @param {string|domNode} message - The message string to be shown inside the dialog.
         * See {@link module:IconAndMessageWidget} for more details
         *
         * @param {object} options - This object can be used to configure to the dialog.
         * @param {string} options.icon="none" The icon for the dialog can be preset icons
         * from {@link module:IconAndMessageWidget} or an image data URI.
         * @param {string} options.customIconAltText="" The alt-text of non-preset icons for the dialog
         * @Param {boolean} options.showCancelButton=false - shows a cancel button in the dialog
         * @Param {boolean} options.cancelButtonText="Cancel" - The display text on the button
         * @param {function} options.cancelCallback - function to execute when the progress
         * is cancelled by the user.
         * @param {string} options.data-test-id="ProgressDialog" - Test ID string for testing
         * <tr><td colspan="4"><strong>Pass through parameters to the {@link module:ProgressBar}
         * widget</strong></td></tr>
         * @param {number} options.value=0 - The value passed to the progress bar
         * @param {boolean} options.indeterminate=false - change the progress bar to an
         * indeterminate one.
         * @param {boolean} options.showPercentage=false - Toggle percentage text on progress bar
         * @param {string} options.colorStyle="info" (DEPRECATED use the property "color" instead) The color style for the progress bar
         * @param {string} options.color="info" The color of the progress bar
         * @param {String | number} options.specifiedFocusItem -
         * If string is supplied, it corresponds to the id of the domNode that determines which
         * item in the dialog content area will have focus when the dialog shows up.
         * If number is supplied, the only acceptable value is 1 which corresponds to the CANCEL
         * button receiving default focus.
         * If this option is not specified or the specifiedFocusItem couldn't be found, the first
         * focusable element in the dialog will get focus when the dialog shows up.
         *
         * @return {object} dialog - return the modal dialog widget.
         * This object has the following members added to it for convenience
         * <li>ProgressBar - {@link module:ProgressBar} widget within the dialog</li>
         * <li>updateMessage(str) - to change the message displayed in the dialog</li>
         * <li>closeProgressDialog - to close the dialog when the task is done</li>
         * <li>disableCancelButton - to disable the cancel button</li>
         * <li>enableCancelButton - to enable the cancel button</li>
         *
         * @example <caption>Simple Progress Dialog</caption>
         * var dlg = Notifications.displayProgressDialog("Loading Data!", "Please Wait ...");
         * dlg.progressBar.set("value",50);
         * dlg.updateMessage("Half way there!!!");
         * // do more work.
         * // update to 100%
         * dlg.progressBar.set("value",100);
         * dlg.updateMessage("Done!!!");
         * dlg.closeProgressDialog();
         *
         *
         * @example <caption>Progress dialog with cancel action</caption>
         * var options = {
         *     showCancelButton: true,
         *     cancelButtonText: "STOP",
         *     cancelCallback: function(eventData) {
         *         // Stop the task and then destroy dialog
         *         eventData.dialog.closeProgressDialog();
         *     }
         * }
         * var dlg = Notifications.displayProgressDialog("Loading Data!", "Please Wait ...",
         *                                                options);
         *
         * @example <caption>Indeterminate Progress dialog with icon and message</caption>
         * var options = {
         *     icon: "warning",
         *     colorStyle: "warning",
         *     indeterminate: true
         * }
         * var dlg = Notifications.displayProgressDialog("Loading Data!", "Some thing is wrong!!!
         *                                                we are loading data.", options);
         * // Run the indeterminate task
         * // Then destroy the dialog
         * dlg.closeProgressDialog();
         *
         */
        displayProgressDialog: function(title, message, userOptions) {
            var dialogName = "ProgressDialog";
            userOptions = userOptions || {};
            var className = (userOptions.className === undefined) ? "" : " " + userOptions.className;

            var msgDiv = '<div class="mwProgressDialogMessage" data-test-id="progress-message-node"></div><div class="mwProgressDialogProgressBar"></div>'; // eslint-disable-line max-len
            var im = this._createIconAndMessageWidget(msgDiv, "none", userOptions);

            var progressBarNode = im.messageNode.children[1];
            var progressMessageNode = im.messageNode.children[0];
            _setMessageHelper(progressMessageNode, message);

            var progressBar = new ProgressBar({
                indeterminate: (userOptions.indeterminate === undefined) ? false : userOptions.indeterminate, // eslint-disable-line max-len
                value: (userOptions.value === undefined) ? 0 : userOptions.value,
                color: userOptions.color || userOptions.colorStyle || "Info", // eslint-disable-line max-len
                showPercentage: (userOptions.showPercentage === undefined) ? false : userOptions.showPercentage, // eslint-disable-line max-len
                "data-test-id": dialogName + "ProgressBar"
            });
            progressBar.placeAt(progressBarNode);
            progressBar.startup();
            // To avoid flashing/resize setup the width an estimated width for the progressBar
            progressBar.resize({ w: (im.icon === "none") ? 360 : 317 });
            this._setupIconAndMessageWidget(im);

            // Create Buttons if showCancelButton === true;
            var button = [];
            var isCancelable = (userOptions.showCancelButton === undefined) ? false : userOptions.showCancelButton;
            if (isCancelable) {
                button = [{
                    type: ButtonEnum.CANCEL.type,
                    label: (userOptions.cancelButtonText === undefined) ? ButtonEnum.CANCEL.label : userOptions.cancelButtonText, // eslint-disable-line max-len
                    "data-test-id": dialogName + "Button1"
                }];
            }

            var dlg = Dialogs.createDialog({
                title: title,
                dialogType: Dialogs.MODAL,
                content: im,
                buttons: button,
                draggable: true,
                closeOnEscape: isCancelable,
                hideCloseButton: !isCancelable,
                specifiedFocusItem: this._parseAndGetSpecifiedFocusedItem(userOptions, button),
                "className": "mw" + dialogName + className,
                "data-test-id": userOptions["data-test-id"] ? userOptions["data-test-id"] : dialogName // eslint-disable-line max-len
            });

            if (isCancelable) {
                var callbackData = {
                    eventName: dialogName + "Closed",
                    dialogTitle: title,
                    response: "cancel",
                    buttonText: button[0].label,
                    dialog: dlg
                };
                var cancelFunctionToEval = userOptions.cancelCallback ? userOptions.cancelCallback : function() {}; // eslint-disable-line max-len
                var closeCallback = function() {
                    cancelFunctionToEval(callbackData);
                };
                dlg.on('close', closeCallback);
                dlg.addButtonEventListener(ButtonEnum.CANCEL, closeCallback, false);

                // Workaround to prevent mw-dialogs from closing
                // A common usage is for the author to control when a progress dialog closes
                dlg._destroyCache = dlg.destroy;
                dlg.destroy = function () {};
                dlg.closeProgressDialog = function () {
                    this.destroy = this._destroyCache;
                    this.destroy();
                };
                dlg.disableCancelButton = function () {
                    this.disableButton(button[0]);
                };
                dlg.enableCancelButton = function () {
                    this.enableButton(button[0]);
                };
            } else {
                dlg.closeProgressDialog = function () {
                    this.destroy();
                };
            }

            dlg.progressBar = progressBar;
            dlg.progressMessageNode = progressMessageNode;
            dlg.updateMessage = function(message) {
                _setMessageHelper(this.progressMessageNode, message);
            };

            return dlg;
        }
    };

    /**
     * @private method to resize dialog body size if button text is too large
     */

    var _setMessageHelper = function(node, msg) {
        if (typeof msg === "string") {
            node.style.whiteSpace = "pre-wrap";
        }
        html.set(node, msg, {
            parseContent: true
        });
    };

    

    //>>excludeStart("GBTExperimentalFeature", true);

    // Ignore JSDoc for this as it is experimental
    // /**
    //  * <strong>EXPERIMENTAL API</strong>
    //  *
    //  * displayAlertBanner method will create an alert banner inside the web app.
    //  * The default alert banner will have a message, title, error icon and an OK button.
    //  * The Banner will wipe in, stay visible for a default duration of time,
    //  * and then wipe out.
    //  * Users can configure the banner further.
    //  *
    //  * @static
    //  *
    //  * @param {string} title - The title to be shown.
    //  *
    //  * @param {string} message - The message string to be shown.
    //  *
    //  * @param {object} [options] - This object can be used to configure the alert dialogs.
    //  * Currently supported options include following:
    //  * @param {string} [options.icon="error"] The icon for the dialog can be preset icons
    //  * from {@link module:IconAndMessageWidget} or an image data URI.
   //   * @param {string} options.customIconAltText="" The alt-text of non-preset icons for the dialog
    //  * @param {number} [options.duration=4000] determines the duration in milliseconds for
    //  *  which the banner is visible.
    //  * @param {function} [options.closeCallback] function to execute when the alert is
    //  * closed by the user.
    //  * @param {string} [options.data-test-id="AlertBanner"] test ID string for testing.
    //  *
    //  * @return {object} banner - return the banner widget
    //  *
    //  * @example <caption>Basic alert banner</caption>
    //  * Notifications.displayAlertBanner("Invalid File Entered",
    //  *                                  "You entered an invalid file name. Please Check");
    //  * // Shows an alert banner with error icon and OK button.
    //  *
    //  * @example <caption>Basic alert banner with different Icon</caption>
    //  * var options = {
    //  *     icon: "info"
    //  * }
    //  * Notifications.displayAlertDialog("Files Uploaded",
    //  *               "Your files have now been uploaded to the MATLAB cloud!", options);
    //  * // Shows an alert banner with information icon and OK button.
    //  */
    _notifications.displayAlertBanner = function(title, message, userOptions) {
        kernel.experimental("Notifications.displayAlertBanner");
        var dialogName = "AlertBanner";
        userOptions = userOptions || {};

        // Create icon and message widget
        var im = this._createIconAndMessageWidget(message, "error", userOptions);

        // Setup callback data and user callback (if any)
        var closeFunctionToEval = userOptions.closeCallback ? userOptions.closeCallback : function() {}; // eslint-disable-line max-len
        var callbackData = {
            eventName: dialogName + "Closed",
            dialogTitle: title,
            response: "closed"
        };

        // Setup banner
        var banner = new BannerWidget({
            title: title,
            content: im,
            duration: userOptions.duration ? userOptions.duration : 4000,
            "class": "mw" + dialogName,
            "data-test-id": userOptions["data-test-id"] ? userOptions["data-test-id"] : dialogName // eslint-disable-line max-len
        });

        banner.set("onHide", function() {
            closeFunctionToEval(callbackData);
            banner.destroyRecursive();
        });

        // Show the widget
        banner.placeAt(baseWin.body());
        banner.startup();
        banner.show();

        return banner;
    };

    //>>excludeEnd("GBTExperimentalFeature");

    return _notifications;
});
