
define([
    "dojo/_base/config",
    "mw-log/Log"
], function (dojoConfiguration, Log) {
    /**
     * @desc This module provides a <code>deprecated()</code> method that can be used to deprecate
     * all or some parts of an AMD module's API. It constructs a consistent deprecation message
     * based on the configuration parameters.
     *
     * @copyright Copyright 2015 The MathWorks, Inc.
     * @exports MW/utils/deprecation
     */
    var deprecation = {

        /**
         *  @desc
         *  Constructs the deprecated message and logs it to the console.
         *
         *  @param {Object} config - This is a configuration object to specify all the properties
         *  for logging a informational deprecation message.
         *  @param {Object} config.deprecated - This parameter specifies detials about the behavior
         *  being deprecated
         *  @param {String} config.deprecated.module - Module to which the deprecated behavior
         *  belongs to
         *  @param {String} config.deprecated.method - Method name being deprecated.
         *  @param {Object} config.replacement - This is an optional property used to specify
         *  details of behavior to which web widgets author wants to migrate his consumers to.
         *  @param {String} config.replacement.module - Module to which the consumers should migrate
         *  to
         *  @param {String} config.replacement.method - Method to which the consumers should migrate
         *  to
         *  @param {String} config.removalVersion - Version in which the behavior is expected to be
         *  deleted
         *  @param {Number} config.geckNumber - Geck number associated with this deprecation. Note
         *  that 'g' will be prepended to the number passed.
         *  @param {String} config.deprecationLevel - Either deprecation.WARN or deprecation.ERROR.
         *  Defaults to deprecation.WARN.
         *  @param {String} config.additionalInformation - Any other additional information that
         *  user wants to put in.
         *  @param {Boolean} window.showDeprecationsAsErrors - If this parameter is set to true, all
         *  the deprecation message will be logged as errors.
         *  @example <caption> Deprecation API usage to replace a module</caption>
         *  deprecation.deprecated({
         *      deprecated: {module: "dijit/button"},
         *      removalVersion: "R2015b",
         *      replacement: {module: "MW/button"},
         *      geckNumber: 1206088
         *  });
         *  @static
         *  @returns {void} Logs a deprecation error or message to console.
         */
        deprecated: function (config) {

            var message, consoleLevel,
                displayDeprecationMessages = !!dojoConfiguration.isDebug ||
                    !!window.showDeprecationsAsErrors;

            if (displayDeprecationMessages && this._isConfigValid(config)) {

                consoleLevel = ((!!window.showDeprecationsAsErrors ||
                config.deprecationLevel === this.ERROR) ? this.ERROR : this.WARN);

                //construct deprecation warning using various parameters
                message = "DEPRECATED: ";

                // Step1: Construct FQN
                if (config.deprecated) {

                    message += config.deprecated.module;

                    if (config.deprecated.method) {
                        message += "." + config.deprecated.method + "()";
                    }
                    message += ".";
                }

                //Step2: Construct replacement
                if (config.replacement) {

                    message += " Use " + config.replacement.module;

                    if (config.replacement.method) {
                        message += "." + config.replacement.method + "()";
                    }
                    message += " instead.";
                }
                if (config.removalVersion) {
                    message += " Will be removed in version: " + config.removalVersion + ".";
                }

                if (config.geckNumber) {
                    message += " Please use g" + config.geckNumber + " to track latest " +
                        "information regarding this deprecation";
                }

                if (config.additionalInformation) {
                    message += " " + config.additionalInformation;
                }

                Log[consoleLevel](message);
            }
        },

        /**
         * @desc This function verifies that the consumer of this API sets the required properties
         * correctly.
         * @param {Object} deprecationConfig - Deprecation configuration passed
         * @returns {boolean|*}
         * @private
         */
        _isConfigValid: function (deprecationConfig) {
            var isConfigurationValid = !!deprecationConfig.deprecated &&
                typeof deprecationConfig.deprecated === "object" &&
                deprecationConfig.deprecated.hasOwnProperty("module");

            if (deprecationConfig.replacement) {
                isConfigurationValid = isConfigurationValid
                    && typeof deprecationConfig.replacement === "object" &&
                    deprecationConfig.replacement.hasOwnProperty("module");
            }

            if (deprecationConfig.geckNumber) {
                isConfigurationValid = isConfigurationValid
                    && typeof deprecationConfig.geckNumber === "number";
            }

            if (!isConfigurationValid) {
                Log.error("Supplied deprecationConfig properties are not valid. Please refer to " +
                    "http://inside.mathworks.com/wiki/Web_Widgets_Deprecation_API " +
                    "for right API");
            }
            return isConfigurationValid;
        }
    };

    Object.defineProperties(deprecation, {
        WARN: {
            value: "warn",
            enumerable: true
        },
        ERROR: {
            value: "error",
            enumerable: true
        }
    });

    return deprecation;
});
