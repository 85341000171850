import ButtonEnum from 'mw-dialogs/ButtonEnum';
import Notifications from 'mw-notifications/Notifications';
import WorkerService from 'mw-resource-acquisition/matlabUiClient/WorkerService';
import pageContext from './context/pageContext';
import faultMessages from './utils/faultMessages';
import { parseQuery } from './utils/utils';

export default class MatlabOnline {
  constructor(rootNodeId, resourceService, gatewayService,
    authnzService, slaService, resourceProxyService, configuration, logger) {
    this._authnzService = authnzService;
    this._slaService = slaService;
    this._resourceService = resourceService;
    this._gatewayService = gatewayService;
    this._resourceProxyService = resourceProxyService;
    this._configuration = configuration;
    this._logger = logger;

    this._rootNode = document.getElementById(rootNodeId);
    this._rootNode.classList.add('mwMatlabOnlineView');
    this._periodicMappingCheck = null;
  }

  async start() {
    clearInterval(this._periodicMappingCheck);

    if (this._configuration.requireAuthentication) {
      let response;
      try {
        response = await this._authnzService.contextTokenCheck();
        if (response.authenticated) {
          this._user = response.subject;
          this._authToken = response.authToken;
          if (this._configuration.slaEnabled) {
            let licResponse = await this._slaService.licenseAgreementCheck(this._configuration.currentMLVersion);
            if (licResponse.accepted) {
              await this._acquireAndSwitch();
            } else {
              document.location.assign(document.location.origin.toString() + '/sla');
            }
          } else {
            await this._acquireAndSwitch();
          }
        } else {
          let endpointResponse = await this._authnzService.getLoginEndpoint(document.location.toString(), { idpId: this._configuration.idpId });
          document.location.assign(endpointResponse.loginUrl);
        }
      } catch (fault) {
        this._showErrorConnectingDialog(
          fault,
          function () {
            window.location.reload();
          });
      }
    } else {
      await this._acquireAndSwitch();
    }
  }

  async workerDisconnectHandler(args) {
    await this._cleanup();
    if (args && args.detail && args.detail.userRequested) {
      await this.logout();
    } else {
      this._showResumeDialog();
    }
  }

  async logout() {
    if (this._configuration.logoutOnSessionEnd && this._authToken) {
      await this._authnzService.tokenLogout(this._authToken);
      this._authToken = '';
      this._user = {};
    }
    await this.clearPageContextAndEndSession();
  }

  // Remove pageContext and end session
  async clearPageContextAndEndSession() {
    window.sessionStorage.removeItem('matlabonlineserver/pageContext');
    window.location.replace("../sessionend");
  }

  async _cleanup() {
    if (this._mapping) {
      try {
        await this._gatewayService.destroyMapping(this._mapping.mappingToken);
      } catch (e) {
        this._logger.warn('Failed to destroy mapping. ' + JSON.stringify(e));
      }
      this._mapping = null;
    }

    if (this._resource) {
      try {
        await this._resourceService.release(this._resource.resourceToken);
      } catch (e) {
        this._logger.warn('Failed to release resource. ' + JSON.stringify(e));
      }
      this._resource = null;
    }

    this._workerService = null;

    clearInterval(this._periodicMappingCheck);
  }

  async _acquireAndSwitch() {

    this._showSpinner();
    this._hideMatlabView();

    try {
      await this._getResourceAndMapping();

      // override service endpoints 
      this._resourceProxyService._dispatcher.registry.setRouteMetadata(this._resourceProxyService._serviceRoute, { endpoint: this._mapping.clientUrl + '/service/' + this._resourceProxyService._serviceRoute });
      this._resourceProxyService._dispatcher.registry.setRouteMetadata('messageservice', { endpoint: this._mapping.clientUrl + "/messageservice/json/secure" });

      let staticContentURL = this._resource.annotations.staticContentURL;
      // if relative, add the mapping url
      if (!(/^https?:\/\//i.test(staticContentURL))) {
        // iframe to be loaded with the same URL as the host due to cross-origin issues with Post Message Protocol
        let contextRoot = window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/matlabonline"));
        staticContentURL = window.location.origin + contextRoot + staticContentURL;
      }
      let matlabOnlineUrl = new URL(staticContentURL + this._configuration.matlabView.path);
      matlabOnlineUrl.searchParams.set("mre", this._mapping.clientUrl);

      let clientUrl = new URL(this._mapping.clientUrl)
      if ("/" != clientUrl.pathname) {
        try {
          await this._resourceProxyService.setClientURL(clientUrl);
        } catch (fault) {
          this._logger.warn('Unable to set client url', clientUrl);
          throw "Unable to set client url";
        }
      }

      if (this._configuration.debug) {
        document.title = 'MATLAB - ' + this._resource.resourceToken +
          ', ' + this._mapping.mappingToken + ', ' + this._mapping.clientUrl;
      } else {
        if (this._configuration.multipleSessionEnabled) {
          let sessionIndex = this._mapping.index + 1;
          document.title = 'MATLAB Online ' + (this._resource.labels.displayName || "") + "-" + sessionIndex;
        } else {
          document.title = 'MATLAB Online ' + (this._resource.labels.displayName || "");
        }
      }

      try {
        let query = parseQuery();
        let storage = '';
        let metadata = '';
        let addonsDirectory = '';
        if (this._resource.storage != null && this._resource.storage.length !== 0) {
          metadata = this._resource.storage[0].metadata
          addonsDirectory = (metadata.addons) ? metadata.addons.directory : "";
          storage = metadata.profileDetail;
        }

        if (storage && storage.startDirectory && storage.mountedDirectories) {
          var mountedDirectories = storage.mountedDirectories;
          // Checks and removes the start directory from the mounted directories
          var startDirectoryIndex = mountedDirectories.indexOf(storage.startDirectory);
          if (startDirectoryIndex > -1) {
            mountedDirectories.splice(startDirectoryIndex, 1);
          }
          // Pings MATLAB to avoid a race condition of creating multiple sessions while loading webgui
          await this._resourceProxyService.pingMATLAB();

          const matlabuiLoadTimeoutInSeconds = (this._configuration.matlabView.loadTimeoutInSeconds > 0) ? this._configuration.matlabView.loadTimeoutInSeconds : 60;
          this._workerService = await WorkerService.create(matlabOnlineUrl, this._rootNode, matlabuiLoadTimeoutInSeconds * 1000);
          this._workerService.addEventListener('request/Disconnect', this.workerDisconnectHandler.bind(this));

          // Compute remaining lifetime based on the current time
          const maxSessionTimeout = new Date(this._resource.leaseEndDateTime) - new Date();
          const timeoutObj = { sessionTimeoutSeconds: this._resource.inactivityTimeoutMinutes * 60, maxSessionTimeoutSeconds: Math.floor(maxSessionTimeout/1000)};
          this._workerService.setSessionTimeoutInformation(timeoutObj);

          const resumePreviousSession = false;
          let isAddonsExplorerSupported = false
          // Works only with these domains with default ports only
          if (window.location.href.includes(".mathworks.com") || window.location.href.includes(".mwcloudtest.com")) {
            isAddonsExplorerSupported = true
          }
          await this._workerService.configureForUser(storage.startDirectory, this._user, mountedDirectories, storage.restrictNavigationOutsideMounts, addonsDirectory, resumePreviousSession, isAddonsExplorerSupported, false, null, 'default');
        } else {
          this._logger.warn('Storage information is empty');
          // TODO: Reevaluate the proceed without storage user experience
          throw "There seems to be an error while setting up storage for MATLAB";
        }

        // selectively reload for now
        if (this._configuration.hideQueryParams && (query.storageprofile || query.resourceDefinitionId)) {
          window.location = window.location.pathname
        }

        this._hideSpinner();
        this._setMATLABHelpCookie();
        this._showMATLABView();

        // Set mapping cookie one time to get the recommendedDelay & numRetries
        let resp = await this._gatewayService.setMappingCookie(this._mapping);
        let recommendedDelay, numRetries;
        if (resp != null) {
          recommendedDelay = resp.recommendedDelayInSeconds;
          numRetries = resp.numRetries;
        }

        // peridically check to see if the mapping is still active
        this._periodicMappingCheck = setInterval(async function () {
          let count = 0;
          let retry = false;
          // Call setmappingcookie after every recommendedDelay (which is usually half of token lifetime unless otherwise specified)
          do {
            try {
              let resp = await this._gatewayService.setMappingCookie(this._mapping);

              let mappings = resp.mappings;
              // More than one mapping; return an error - no retries here
              // the length == 0 solves the issue g3130246, to visit again during multisession support
              if (mappings.length > 1 || mappings.length == 0) {
                clearInterval(this._periodicMappingCheck);
                this._hideMatlabView();
                this._workerService = null;
                // Session being transferred
                this._showErrorConnectingDialog(
                  { messageId: 'service_failure.request.existing_in_use_mapping' },
                  this.clearPageContextAndEndSession.bind(this));
              }

              // Invalid mapping; return an error after max retries reached
              if (mappings.length == 1) {
                if (mappings[0].mappingToken != this._mapping.mappingToken) {
                  retry = true;
                  if (++count == numRetries) {
                    clearInterval(this._periodicMappingCheck);
                    this._hideMatlabView();
                    this._workerService = null;
                    this._showErrorConnectingDialog(
                      { messageId: 'service_failure.request.keepalive_failed' },
                      function () {
                        window.location.reload();
                      });
                  }
                } else {
                  // Reset counter and retry
                  retry = false;
                  count = 0;
                }
              }
            } catch (fault) {
              // If set mapping cookie request itself fails; this block is executed
              // Retry until max numRetries reached
              retry = true;
              if (++count == numRetries) {
                clearInterval(this._periodicMappingCheck);
                this._hideSpinner();
                this._hideMatlabView();
                this._showFailedToConfigureDialog(fault);
              }
            }
          } while (retry && count < numRetries);
        }.bind(this), recommendedDelay * 1000);
      } catch (fault) {
        this._hideSpinner();
        this._hideMatlabView();
        this._showFailedToConfigureDialog(fault);
      }
    } catch (fault) {
      this._logger.warn('Unable to load MATLAB Online. ', JSON.stringify(fault));
      if (fault.faultId.toString().includes("lease_limit_reached")) {
        this._hideSpinner();
        this._hideMatlabView();
        this._showErrorConnectingDialog(
          { messageId: 'service_failure.request.existing_in_use_mapping' },
          this.logout.bind(this));
      } else if (fault.faultId.toString().includes("no_resource_definitions")) {
        await new Promise((resolve, reject) => {
          this._showNoResourceDefinationDialog(() => {
            this.logout();
            resolve();
          });
        });
      } else {
        this._hideSpinner();
        this._hideMatlabView();
        this._cleanup();
        this._showErrorConnectingDialog(fault, this.logout.bind(this));
      }
    }
  }

  async _getResourceAndMapping() {
    let query = parseQuery();
    if (this._configuration.resourceFromQuery) {
      // Parse the query parameter for a config value which is a base64 encoded
      // object containing the resource token. Save the query parameter in the
      // page context so that the location can be cleared of the query string.
      // Use the resource token to get the full resource object.
      let config;
      if (query.config) {
        history.pushState(null, 'MATLAB Online', document.location.pathname);
        try {
          config = JSON.parse(atob(query.config));
          pageContext.setValue('queryConfig', config);
        } catch (ex) {
          this._logger.error('Error while parsing config: ' + query.config);
        }
      } else {
        config = pageContext.getValue('queryConfig');
      }

      this._resource = await this._resourceService.getResource(config.resourceToken);
    } else {
      let spec = this._configuration.resourceSpec;
      let config = this._configuration.resourceConfig;

      if (query.storageprofile) {
        config.storage[0].profileName = query.storageprofile
      }

      // TODO: Should we put a function here to block the resource mapping?
      // this._doAWSDelegation();
      // Check aws status and assume role
      if (this._user.extra && this._user.extra.awsiamrolestatus && this._user.extra.awsiamrolestatus[0] == "awaitSelection") {
        console.log("AWS delegation enabled, proceed to role selection")
        let availableRoles = JSON.parse(this._user.extra.awsiamroles);
        availableRoles.sort((a, b) => (a.AccountID > b.AccountID) ? 1 : (a.AccountID === b.AccountID) ? ((a.Name > b.Name) ? 1 : -1) : -1);

        var currentAccountID;
        var msgStr = '<strong>Delegated AWS Access</strong><br/>';
        for (let i = 0; i < availableRoles.length; ++i) {
          if (availableRoles[i].AccountID != currentAccountID) {
            currentAccountID = availableRoles[i].AccountID;
            msgStr += '<hr><b>Account:</b> ' + currentAccountID + "<br/>"+ '<b>Principal:</b> ' + availableRoles[i].PrincipalARN + "<br/>" + "<b>Roles:</b><br/>"
          }
          if (i == 0) {
            msgStr += '<label><input type="radio" name="awsiamRadio" value=' + JSON.stringify(availableRoles[i]) + ' checked />' + availableRoles[i].Name + '</label><br/>';
          } else {
            msgStr += '<label><input type="radio" name="awsiamRadio" value=' + JSON.stringify(availableRoles[i]) + ' />' + availableRoles[i].Name + '</label><br/>';
          }
        }

        var selectedRole
        await new Promise(function (resolve, reject) {
          var options = {
            icon: 'info',
            closeCallback: function (e) {
              var selectedRadio = document.querySelector('.mwAlertDialog input[type="radio"][name="awsiamRadio"]:checked');
              selectedRole = selectedRadio.value
              resolve()
            }
          };

          Notifications.displayAlertDialog('Selection', msgStr, options);
        }.bind(this));
        // Send the new request once the selection was made
        let resp = await this._authnzService.awsSelectRole(selectedRole);
        if ( resp.subject && resp.subject.extra && resp.subject.extra.awsiamrolestatus && resp.subject.extra.awsiamrolestatus[0] == "selected") {
          this._user = resp.subject
        } else {
          this._user.extra.awsiamrolestatus = resp.subject.extra.awsiamrolestatus
          this._user.extra.failuremode = resp.subject.extra.failuremode
        }
      }
      if (this._user.extra && this._user.extra.awsiamrolestatus && this._user.extra.awsiamrolestatus[0] == "error") {
        if (this._user.extra.failuremode && this._user.extra.failuremode[0] == "halt") {
          await new Promise(function (resolve, reject) {this._showErrorAWSDialog(resolve)}.bind(this));
          await new Promise(function (resolve, reject) {this.logout()}.bind(this));
        } else {
          await new Promise(function (resolve, reject) {this._showWarnAWSDialog(resolve)}.bind(this));
        }
      }

      let oldMapping = pageContext.getValue('core/mapping', null);

      // If mapping does not exist in pageContext then it's a new tab
      if (oldMapping == null) {
        if (query.resourceDefinitionId) {
          spec.resourceDefinitionId = query.resourceDefinitionId;
        } else if (this._configuration.hpoolEnabled) {
          let resourceDefinitions = await this._resourceService.listResourceDefinitions();
          // No resource available. Typically triggered when matlab-pool is being deployed but not available yet
          if (resourceDefinitions == null || resourceDefinitions.length == 0) {
            await new Promise((resolve, reject) => {
              this._showNoResourceDefinationDialog(() => {
                this.logout();
                resolve();
              });
            });
          } else if (resourceDefinitions.length > 1) {
            // Sort alphabetically based on description
            resourceDefinitions.sort((a, b) => (a.labels.displayName > b.labels.displayName) ? 1 : -1)
            var msgStr = '<strong>Select MATLAB</strong><br/><br/>'
            for (let i = 0; i < resourceDefinitions.length; ++i) {
              msgStr += '<label><input type="radio" name="hpoolRadio" value=\'{"resDefId":"' + resourceDefinitions[i].resourceDefinitionId + '","resType":"' + resourceDefinitions[i].type +'"}\' checked />' + resourceDefinitions[i].labels.displayName + '</label><br/>';
            }
            await new Promise(function (resolve, reject) {
              var options = {
                icon: 'info',
                closeCallback: function (e) {
                  var selectedRadio = document.querySelector('.mwAlertDialog input[type="radio"][name="hpoolRadio"]:checked');
                  const radioValueAsObject = JSON.parse(selectedRadio.value)
                  spec.resourceDefinitionId = radioValueAsObject.resDefId
                  spec.type = radioValueAsObject.resType
                  resolve()
                }
              };

              Notifications.displayAlertDialog('Selection', msgStr, options);
            }.bind(this));
          }
        }

        if (this._configuration.multipleSessionEnabled) {
          this._resource = await this._resourceService.acquire(spec, config);
        } else {
          let resources = await this._resourceService.listResources(spec);
          if (resources != null && resources.length == 1) {
            this._resource = resources[0]
          } else {
            this._resource = await this._resourceService.acquire(spec, config);
          }
        }
        
      } else {
        // If mapping exists in pageContext; then it's a page refresh and hence use the same resource
        try {
          this._resource = await this._resourceService.getResource(oldMapping.resourceToken);
          // Verify if the resource is valid otherwise Acquire a new one to proceed
          if (this._resource == null || this._resource.state != "ACQUIRED") {
            this._resource = await this._resourceService.acquire(spec, config);
          }
        } catch (error) {
          // If GetResource returns a fault, we should go ahead and Acquire
          this._resource = await this._resourceService.acquire(spec, config);
        }
      }
    }

    let oldMapping = pageContext.getValue('core/mapping', null);

    let mappings = await this._gatewayService.listMappings(this._resource.resourceToken);
    if (mappings.length > 0) {
      let now = Date.now();
      for (let i = 0; i < mappings.length; ++i) {
        let mapping = mappings[i];
        let lastUsed = new Date(mapping.lastUsedDateTime);
        // TODO: Enable this again. This can chaneg based on the gateway mapping timeout and refresh cycle, might not be 45s
        // if the mapping hasn't been used in 45 seconds then assume it's
        // dead and just destroy it
        // if (now - lastUsed > 45000) {
          // TODO: revisit when we support multiple concurrent sessions
        //  await this._gatewayService.destroyMapping(mapping.mappingToken);

          // reuse the mapping if it was previously used by this tab (ie: page refresh)
        // } else 
        if (oldMapping && oldMapping.mappingToken === mapping.mappingToken) {
          this._mapping = oldMapping;

          // there may be a currently open and active tab, so ask if it should be disconnected
        } else {
          let destroy = await this._showExistingMappingsDialog(); // Do you want to disconnect other?
          if (destroy) { //  Yes, disconnect the other session
            // Refreshes the list of mappings.
            // Needed if user has multiple clients waiting and is being transferred from one client to another
            let mappings = await this._gatewayService.listMappings(this._resource.resourceToken);
            for (let i = 0; i < mappings.length; ++i) {
              // TODO: revisit when we support multiple concurrent sessions
              // release any existing mappings to disconnect those clients
              await this._gatewayService.destroyMapping(mappings[i].mappingToken); // TODO: Remove the gateway token as well to end the session right the way. Ensure the new one sets the gatewaytokencookie

            }
          } else { // No, exit and don't disconnect the other session
            await new Promise(function (resolve, reject) {
              this._hideSpinner();
              this._showExistingSessionDialog(
                // Clean session store and disconnect the current login attempt
                this.clearPageContextAndEndSession().bind(this));
            }.bind(this));
          }
        }
      }
    }

    // if we did not find a usable existing mapping then create a new one
    if (!this._mapping) {
      this._mapping = await this._gatewayService.createMapping(this._resource.resourceToken);
    }

    pageContext.setValue('core/mapping', this._mapping);

    // always set the mapping cookie just in case
    await this._gatewayService.setMappingCookie(this._mapping);
  }

  _showResumeDialog() {
    Notifications.displayConfirmDialog(
      'Session Timeout',
      this._getDisplayMsg("session.session_timeout_message"),
      {
        icon: 'info',
        buttonText: [
          'Resume',
          'Exit'
        ],
        defaultAcceptButton: 1,
        closeCallback: (async function (e) {
          if (e.response === 2) {
            this.logout();
          } else if (e.response === 1) {
            await this._cleanup();
            window.location.reload();
          }
        }.bind(this))
      }
    );
  }

  _showFailedToConfigureDialog(fault) {
    Notifications.displayConfirmDialog(
      'MATLAB Session Unresponsive',
      'The MATLAB session you are trying to connect to is not responding. ' + (
        (fault && fault.messageId) ? this._getDisplayMsg(fault.messageId) : ""
      ),
      {
        icon: 'error',
        buttonText: [
          'New session',
          'Exit'
        ],
        defaultAcceptButton: 1,
        closeCallback: (async function (e) {
          if (e.response === 2) {
            this.logout();
          } else if (e.response === 1) {
            await this._cleanup();
            window.location.reload();
          }
        }.bind(this))
      }
    );
  }

  _showNoResourceDefinationDialog(action) {
    Notifications.displayAlertDialog(
      'MATLAB Connection Error',
      'Unable to connect to MATLAB due to one of the following reasons: \n\n1. No MATLAB can be found.\n2. You do not have the required permissions.\n\nFor help, contact your IT administrator.',
      {
        icon: 'warning',
        closeCallback: action
      }
    );
  }
  _showErrorConnectingDialog(fault, action) {
    Notifications.displayAlertDialog(
      'Session Disconnected',
      'Sorry, there was an error connecting to MATLAB Online. ' + this._getDisplayMsg(fault.messageId),
      {
        icon: 'warning',
        closeCallback: action
      }
    );
  }

  _showErrorAWSDialog(resolve) {
    Notifications.displayAlertDialog(
      'Failed connecting to MATLAB Session',
      'Sorry, there was an error delegating your AWS role.\n\nPlease contact with your IT admin for assistance. \n',
      {
        icon: 'error',
        closeCallback: resolve
      }
    );
  }

  _showWarnAWSDialog(resolve) {
    Notifications.displayAlertDialog(
      'Skipping AWS Delegation',
      'Sorry, there was an error delegating your AWS role. MATLAB Online will be available without delegated AWS access.\n\nPlease contact with your IT admin for assistance. \n',
      {
        icon: 'warning',
        closeCallback: resolve
      }
    );
  }

  _showExistingSessionDialog(resolve) {
    Notifications.displayAlertDialog(
      'Existing MATLAB Session',
      this._getDisplayMsg("session.session_exists_message"),
      {
        icon: 'warning',
        closeCallback: resolve
      }
    );
  }

  _showExistingMappingsDialog() {
    let displayMsgFn = this._getDisplayMsg
    return new Promise(function (resolve) {
      Notifications.displayConfirmDialog(
        "Existing MATLAB Session",
        displayMsgFn("session.session_disconnect_question"),
        {
          icon: 'warning',
          buttonText: [
            ButtonEnum.YES.label,
            ButtonEnum.NO.label,
          ],
          defaultAcceptButton: 1,
          closeCallback: (async function (e) {
            if (e.response === 1) {
              resolve(true);
            } else {
              resolve(false);
            }
          }.bind(this))
        }
      );
    });
  }

  _getDisplayMsg(faultMessageId) {
    let displayMsg = faultMessages.faultMessages[faultMessageId];

    if (!displayMsg) {
      displayMsg = '';
    }
    return displayMsg;
  }

  _showSpinner() {
    this._spinnerNode = document.createElement("div");
    this._spinnerNode.className = "mwSpinner";
    this._spinnerNode.innerHTML = `
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
    `;
    this._rootNode.parentElement.insertBefore(this._spinnerNode, this._rootNode);
  }

  _hideSpinner() {
    if (this._spinnerNode) {
      this._rootNode.parentElement.removeChild(this._spinnerNode);
    }
    this._spinnerNode = null;
  }

  _setMATLABHelpCookie() {
    document.cookie = "MW_Doc_Template=ONLINE||||||||||;path=/help";
  }

  _showMATLABView() {
    this._rootNode.classList.remove('hidden');
  }

  _hideMatlabView() {
    this._rootNode.classList.add('hidden');
    this._rootNode.innerHTML = '';
  }
}
