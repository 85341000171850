/**
 * IconAndMessageWidget is used to create a widget with two html components:
 * Image(IMG) and message text(div)
 *
 * @module IconAndMessageWidget
 * @mixes  dijit/_WidgetBase
 * @mixes  dijit/_TemplatedMixin
 * @example <caption>Widget with warning icon</caption>
 * var im = new IconAndMessageWidget({icon: 'warning', message: 'This is your last warning!!!'});
 *
 * im.placeAt("someDivId");
 * im.startup();
 *
 * @copyright Copyright 2014 MathWorks, Inc.
 */
define([
    "dojo/_base/declare", // eslint-disable-line module-sorted
    "dijit/_WidgetBase",
    "dijit/_TemplatedMixin",
    "dojo/html",
    "dojo/i18n!./l10n/nls/NotificationsResources"
], function(declare, _WidgetBase, _TemplatedMixin, html, l10nStrs) {

    //predefined icon-type to corresponsing alt-text MAP
    var iconAltTextMap = {
        error: l10nStrs.errorIconAltText,
        warning: l10nStrs.warningIconAltText,
        info: l10nStrs.infoIconAltText,
        question: l10nStrs.questionIconAltText,
        success: l10nStrs.successIconAltText,
        none: ""
    };

    return declare([_WidgetBase, _TemplatedMixin], {

        templateString: '<div class="${baseClass}">' +
            '<img class="${baseClass}Icon" src="" draggable="false"  ondragstart="return false;" data-dojo-attach-point="iconNode">' +
            '<div class="${baseClass}Message" data-test-id="message-node" data-dojo-attach-point="messageNode">${!message}</div>' + // eslint-disable-line max-len
            '</div>',

        baseClass: "mwIconAndMessageWidget",

        /**
         * Icon string
         * @property {string} [icon="error"] - must be either error, warning, info, question,
         * success, none, a data-uri to an image, an absolute or relative URL of an image file, or a CSS class to a background-image
         * @instance
         */
        icon: "error",

        /**
         * custom icon alt-text
         * @property {string} [customIconAltText=""]
         * alt-text for icons which are not predefined (NOT error|warning|info|question|success|none)
         * @instance
         */
        customIconAltText: "",

        /**
         * Message Content
         * @property {string|domNode} [message=""] - the Message text or the domNode
         *  that will be shown next to the icon.
         *  String can contain HTML sequences. Whitespace will be escaped.
         *  String with inline HTML dojo widgets will be parsed and started using dojo/html
         * @instance
         */
        message: "",

        /**
         * @private
         * @instance
         */
        _setIconAttr: function(type) {
            var iconId = "";
            // We only want to set it if it's a non-empty string
            if (!type) {
                type = "error";
            }
            this._set("icon", type);

            var iconClass = this.baseClass + "Icon";
            // Remove all classes and reset the icon mode
            this.iconNode.src = this._ICON_blank;
            this.iconNode.className = "";
            this.iconNode.classList.add(this.baseClass + "Icon");
            if (type.match(/^(error|warning|info|question|success|none)$/)) {
                // Type is one of the supported presets
                this.iconNode.classList.add("mwAlertIcon32", this._getIconClassName(this.icon));
                iconId = type;
            } else if (type.match(/^(data:image\/)/i)) {
                // Type is an image dataURI
                iconId = "customDataURI";
                this.iconNode.src = type; 
            } else if (type.match(/\./)) { // if type has a ".", assume it's a URI, else assume it's a class name (fall through)
                // Type is an image URI
                iconId = "customURI";
                this.iconNode.src = type;
            } else {
                // Type is a CSS class to a background-image.
                iconId = "customCSSClass";
                this.iconNode.classList.add(type);
            }
            this.iconNode.width; // to insure updating the width and height properties
            this.iconNode.height;
            this.iconNode.setAttribute("data-icon-id", iconId);
            this._setImgAltAttributeValue();
        },

        /**
         * set the alt-text for icons which are not predefined
         * @param {*alt-text for icons which are not predefined} altText 
         */
        _setCustomIconAltTextAttr: function(altText) {
            this._set("customIconAltText", altText);
            this._setImgAltAttributeValue();
        },

        /**
         * sets the value of the "alt" property of the IMG HTML element
         * based on the value of the icon-type
         */
        _setImgAltAttributeValue: function() {
            var altText = iconAltTextMap[this.icon] || this.customIconAltText;
            this.iconNode.setAttribute("alt", altText);
        },

        /**
         * @private
         * @instance
         */
        _getIconClassName: function(type) {
            return "mwAlertIcon" + type.charAt(0).toUpperCase() + type.slice(1);
        },

        /**
         * If message is a string, collapse consecutive white spaces and break on newline chars
         * @private
         * @instance
         */
        _setMessageAttr: function(msg) {
            this._set("message", msg);
            if (typeof msg === "string") {
                this.messageNode.style.whiteSpace = "pre-wrap";
            }
            html.set(this.messageNode, msg, {
                parseContent: true
            });
        },

        // 1x1 Blank icon to prevent the browser from adding a gray border around image tag
        _ICON_blank: "data:image/gif;base64,R0lGODlhAQABAJAAAP8AAAAAACH5BAUQAAAALAAAAAABAAEAAAICBAEAOw==" // eslint-disable-line max-len

    });
});
