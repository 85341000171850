/**
 * ProgressBar is used to create a progress bar widget to show determinate or indeterminate progress
 *
 * <br/>
 * <br/>
 * Note: If you are going to be running JS code on the Browser's UI Thread and keeping it busy.
 * This widget will not draw. You need to chunk up your JS task and give the browser time to draw.
 * Or move your JS processing to a web worker. Here is some pseudo code to help:
 * <br/>
 * progressBarInstance.set('value', 25); // sets to 25%<br/>
 * // Give the browser sometime to draw, <br/>
 * // before starting your JS code and eating up the UI thread.<br/>
 * setTimeout(myJSTask1, 30); <br/>
 * // At the end of myJSTask1, <br/>
 * // update the progress and then start the next chunk of JS processing.<br/>
 * <br/>
 * <br/>
 *
 * @module ProgressBar
 * @mixes dijit/_WidgetBase
 * @mixes dijit/_TemplatedMixin
 * @mixes ./_ProgressMixin
 *
 * @example <caption>Determinate progress bar widget</caption>
 * var pb = new ProgressBar({value: 25});
 * pb.placeAt(<domNode>);
 * pb.startup();
 *
 * @example <caption>Indeterminate progress bar widget</caption>
 * var pb = new ProgressBar({indeterminate: true});
 * pb.placeAt(<domNode>);
 * pb.startup();
 *
 * @property {number} value=0 - Number between 0-100 to indicate % progress.
 *
 * @property {boolean} indeterminate=false - toggles indeterminate progress bar visual.
 *
 * @property {boolean} showPercentage=true - toggles visibility of the percentage text.
 *
 * @deprecated {@link mw-notifications/ProgressBar#colorStyle} @see {@link mw-notifications/ProgressBar#color} instead.
 * @property {string} colorStyle="Info" - (DEPRECATED use the property color instead) changes colors on the progress bar. 
 * Accepted values are: Info, Error, Warning, Success.
 * 
 * @property {string} color="Info" - changes colors on the progress bar. 
 * Accepted values are: Info, Error, Warning, Success, 6 digit hex code, 3 digit hex code.
 *
 * @property {string} data-test-id="ProgressBar" - testing id.
 *
 * @copyright Copyright 2016 MathWorks, Inc.
 */
define([
    "dojo/_base/declare", // declare
    "dojo/dom-style",
    "dojo/dom-class",
    "dojo/dom-geometry",
    "dijit/_TemplatedMixin",
    "dijit/_WidgetBase",
    "./_ProgressMixin",
    "dojo/text!./templates/ProgressBar.html"
], function(declare, domStyle, domClass, domGeomtry,
    _TemplatedMixin, _WidgetBase, _ProgressMixin, template) {

    var pb = declare([_WidgetBase, _TemplatedMixin, _ProgressMixin], {

        baseClass: "mwProgressBar",

        templateString: template,

        resize: function(dim) {
            var parentWidth;
            if (dim && dim.w) {
                parentWidth = dim.w;
            } else {
                var cb = domGeomtry.getContentBox(this.domNode.parentNode);
                parentWidth = cb.w;
            }
            if (parentWidth === NaN || parentWidth === 0) {
                parentWidth = 100;
            }
            domStyle.set(this.gutterNode, "transform", "scaleX(" + (parentWidth / 100) + ")");
        },

        startup: function() {
            this.inherited(arguments);
            this.resize();
        },

        _setValueAttr: function(newValue) {
            this.inherited(arguments);

            // update progress bar
            domStyle.set(this.progressNode, "transform",
                "translateX(" + (this.value - 100) + "px)");
        },

        // Helper to toggle the animation (if needed)
        _showAnimationWave: function(val) {
            var cName = this.baseClass + 'NoWave';
            if (val === false) {
                domClass.add(this.domNode, cName);
            } else {
                domClass.remove(this.domNode, cName);
            }
        }

    });

    return pb;
});
