/**
 * <strong>EXPERIMENTAL API</strong>
 *
 * Banner create a banner widget at the top of the web app
 *
 * @module BannerWidget
 * @mixes  dijit/_WidgetBase
 * @mixes  dijit/_TemplatedMixin
 * @example <caption>Widget creation</caption>
 * var banner = new this.Banner({
 *     title: 'test title',
 *     content: 'This is a message',
 *     duration: 4000
 * });
 * // Show the widget
 * banner.placeAt(document.body());
 * banner.startup();
 * banner.show();
 *
 * @copyright Copyright 2014 MathWorks, Inc.
 */
define([
    "dojo/_base/declare", // eslint-disable-line module-sorted
    "dijit/_WidgetBase",
    "dijit/_TemplatedMixin",
    "dojo/dom-attr",
    "dojo/_base/lang",
    "dojo/fx"
], function(declare, _WidgetBase, _TemplatedMixin, domAttr, lang, fx) {
    return declare([_WidgetBase, _TemplatedMixin], {

        templateString: '<div class="${baseClass}" style="display:none;">' +
            '<div class="${baseClass}Container" data-dojo-attach-point="containerNode">' +
            '<div class="${baseClass}Title" data-dojo-attach-point="titleNode">${!title}</div>' +
            '<div class="${baseClass}Content" data-dojo-attach-point="contentNode"></div>' +
            '</div>' +
            '</div>',

        baseClass: "mwBanner",

        "data-test-id": "",

        /**
         * Title string
         * @property {string} [title=""] - Title to be used on the banner
         * @instance
         */
        title: "Banner Title",

        /**
         * Content string or widget
         * @property {string|widget} [content=""] - Content can be a string or a dijit widget.
         * @instance
         */
        content: "This is the Banner Content Area",

        /**
         * Duration
         * @property {number} [duration=4000] - Duration in milliseconds for which the banner
         * will be displayed. After which banner wipes-out. 0 means banner never wipes out.
         * @instance
         */
        duration: 4000,

        wipeInDuration: 500,

        wipeOutDuration: 350,

        _hideTimer: 0,

        isVisible: false,

        onHide: function() {},

        postCreate: function() {
            domAttr.set(this.domNode, "data-test-id", this["data-test-id"]);
        },

        _setContentAttr: function(content) {
            if (lang.isString(content)) {
                this.contentNode.innerHTML = content;
            } else { // widget
                content.placeAt(this.contentNode);
                content.startup();
            }
        },

        show: function() {
            if (!this.isVisible) {
                this.set("isVisible", true);
                fx.wipeIn({
                    node: this.domNode,
                    duration: this.wipeInDuration
                }).play();
            }

            if (this.duration > 0) {
                var hideTimer = setTimeout(lang.hitch(this, this.hide), this.duration);
                this.set("_hideTimer", hideTimer);
            }
        },

        hide: function() {
            if (this.isVisible) {
                this.set("isVisible", false);
                clearTimeout(this._hideTimer);
                fx.wipeOut({
                    node: this.domNode,
                    duration: this.wipeOutDuration,
                    onEnd: this.onHide
                }).play();
            }
        }


    });
});