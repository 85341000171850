export default {
  defaultServiceEndpoint: '../service/',
  routes: {},
  matlabView: {
    path: '/index-matlabonlineserver.html',
    loadTimeoutInSeconds: 60
  },
  requireAuthentication: true,
  resourceFromQuery: false,
  resource: {
    configId: 'id'
  },
  resourceSpec: {
    type: 'matlab',
    matchLabels: {
      "mos.mathworks.com/supportsMatlabOnline": 'true'
    }
  },
  resourceConfig: {
    storage: [{
      profileName: ""
    }]
  },
  multipleSessionEnabled: false
};
