 
  //noinspection BadExpressionStatementJS,UnterminatedStatementJS
 define({ 
         ok: "OK",
         cancel: "キャンセル",
         switchPanes: "ウィンドウの切り替え",
         close: "閉じる",
         yes: "はい",
         no: "いいえ",
         help: "ヘルプ",
         submit: "送信",
         save: "保存",
         find: "検索",
         create: "作成",
         dontSave: "保存しない",
         apply: "適用",
         revert: "元に戻す"
     });

