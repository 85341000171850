export default class SLAService {
  constructor (context, configuration, logger) {
    this._context = context;
    this._configuration = configuration;
    this._logger = logger;
    if (this._configuration.slaService) {
      this._serviceName = 'core/sla';
    } else {
      this._serviceName = 'core/authnz';
    }
    
  }

  async licenseAgreementCheck(mlVersion) {
    let mwtype = null;
    if (this._configuration.slaService) {
      mwtype = 'sla/LicenseAgreementCheck';
    } else {
      mwtype = 'authnz/LicenseAgreementCheck';
    }
    let request = {
      mwtype: mwtype,
      mlVersion: mlVersion 
    };

    let route = await this._getServiceRoute();
    let response = await this._context.dispatch(request, route);
    delete response.mwtype; // kind of extraneous since it's always a single type
    return response;
    
  }

  async setLicenseAgreement(mlVersion) {
    let mwtype = null;
    if (this._configuration.slaService) {
      mwtype = 'sla/SetLicenseAgreement';
    } else {
      mwtype = 'authnz/SetLicenseAgreement';
    }
    let request = {
      mwtype: mwtype,
      mlVersion: mlVersion 
    };

    let route = await this._getServiceRoute();
    let response = await this._context.dispatch(request, route);
    delete response.mwtype; // kind of extraneous since it's always a single type
    return response;
    
  }

  async _getServiceRoute () {
    if (!this._serviceRoute) {
      this._serviceRoute = await this._context.lookupRoute(this._serviceName);
    }
    return this._serviceRoute;
  }

}
