// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./themes/default/main.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* This is a temporary file which allows the 2p CSS to be used in 1p, it would be deleted when generatedCSS toolings ready to handle CSS Architecture changes */\n\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1haW4uY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLGdLQUFnSztBQUNoSyxrQ0FBa0MiLCJmaWxlIjoibWFpbi5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIvKiBUaGlzIGlzIGEgdGVtcG9yYXJ5IGZpbGUgd2hpY2ggYWxsb3dzIHRoZSAycCBDU1MgdG8gYmUgdXNlZCBpbiAxcCwgaXQgd291bGQgYmUgZGVsZXRlZCB3aGVuIGdlbmVyYXRlZENTUyB0b29saW5ncyByZWFkeSB0byBoYW5kbGUgQ1NTIEFyY2hpdGVjdHVyZSBjaGFuZ2VzICovXG5AaW1wb3J0ICd0aGVtZXMvZGVmYXVsdC9tYWluLmNzcyc7XG4iXX0= */", "",{"version":3,"sources":["webpack://./mw-dialogs/css/main.css"],"names":[],"mappings":"AAAA,+JAAA;;AAGA,oeAAoe","sourcesContent":["/* This is a temporary file which allows the 2p CSS to be used in 1p, it would be deleted when generatedCSS toolings ready to handle CSS Architecture changes */\n@import 'themes/default/main.css';\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
