export default class Context {
  constructor (configuration, logger) {
    this._configuration = configuration;
    this._defaultServiceEndpoint = configuration.defaultServiceEndpoint ||
                document.location.protocol + '//' + document.location.host + '/service/';
    this._extraRoutes = {};
    this._logger = logger;
  }

  addExtraRoute (routeName, endpoint) {
    // overwrites if there is an extra route
    this._extraRoutes[routeName] = endpoint;
  }

  async dispatch () {
    if (arguments.length === 2) {
      return this.dispatchRequest(arguments[0], arguments[1]);
    } else if (arguments.length === 3) {
      return this.dispatchRequestWithParam(arguments[0], arguments[1], arguments[2]);
    }
  }

  async dispatchRequest (request, route) {
    if (typeof request === 'string') {
      request = {
        'mwtype': request
      };
    }

    let response = await this.sendRequest(route, request);

    if (!response || response.mwtype === 'core/Fault') {
      throw response;
    } else {
      return response;
    }
  }

  async dispatchRequestWithParam (request, param, route) {
    if (typeof request === 'string') {
      request = {
        'mwtype': request,
        'artifactId': param
      };
    }

    let response = await this.sendRequest(route, request);

    if (response.mwtype === 'core/Fault') {
      throw response;
    } else {
      return response;
    }
  }

  async sendRequest (route, request) {
    if (typeof route === 'string') {
      route = await this.lookupRoute(route);
    }
    if (!Array.isArray(route)) {
      throw 'The route must be an array, (try lookupRoute to get a route)';
    }

    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };

    // await response of fetch call
    let httpResponse = await fetch(this._getEndpointForRoute(route),
      {
        method: 'POST',
        mode: 'cors',
        headers: headers,
        credentials: 'include',
        body: JSON.stringify(request)
      });

    let response = await httpResponse.json();
    return response;
  }

  async getEndpoint (serviceName) {
    let route = await this.lookupRoute(serviceName);
    return this._getEndpointForRoute(route);
  }

  _getEndpointForRoute (route) {
    if (!Array.isArray(route)) {
      throw 'The route must be an array, (try lookupRoute to get a route)';
    }

    let url;
    if (this._configuration.routes && this._configuration.routes[route[0]]) {
      url = this._configuration.routes[route[0]];
    } else if (this._extraRoutes && this._extraRoutes[route[0]]) {
      url = this._extraRoutes[route[0]];
    } else {
      url = this._defaultServiceEndpoint + route[0];
    }

    if (url && url.startsWith('//')) {
      // protocol relative
      url = document.location.protocol + url;
    } else if (url && url.startsWith('/')) {
      // absolute path
      url = document.location.origin + url;
    }

    return url;
  }

  async lookupRoute (serviceName) {
    return [serviceName];
  }
}
