/**
 * _ProgressMixin provides base functionality for progress widgets
 *
 * @copyright Copyright 2016 MathWorks, Inc.
 */
define([
    "dojo/_base/declare", // declare
    "dojo/dom-attr",
    "mw-deprecation-utils/deprecation"
], function(declare, domAttr, deprecation) {

    var _progressMixin = declare([], {

        value: 0,

        indeterminate: false,

        showPercentage: true,

        // default progress color
        color: "Info",

        "data-test-id": "",

        constructor: function () {
            this["data-test-id"] = this.baseClass.substring(2);
        },

        _setValueAttr: function(newValue) {
            newValue = Math.min(Math.max(parseInt(newValue), 0), 100);
            this._set("value", newValue);

            // update progress text
            this.progressTextNode.textContent = this.value + '%';

            // set the dom attr for reference:
            domAttr.set(this.domNode, "data-value", this.value);
        },

        _setIndeterminateAttr: function(newValue) {
            this._set("indeterminate", newValue);
            var cName = this.baseClass + "Indeterminate";
            if (this.indeterminate) {
                this.domNode.classList.add(cName);
            } else {
                this.domNode.classList.remove(cName);
            }
        },

        _setShowPercentageAttr: function(newValue) {
            this._set("showPercentage", newValue);
            var cName = this.baseClass + "Invisible";
            if (this.showPercentage) {
                this.progressTextNode.classList.remove(cName);
            } else {
                this.progressTextNode.classList.add(cName);
            }
        },

        _setColorStyleAttr: function(newValue) {
            deprecation.deprecated({deprecated: {module: "mw-notifications/ProgressBar", method: "_setColorStyleAttr"},
                replacement: {module: "mw-notifications/ProgressBar", method: "_setColorAttr"}});+
            
            this.set('color', newValue);
        },

        _getColorStyleAttr: function() {
            return this.color;
        },

        _setColorAttr: function(newValue) {
            if (this._isHexColorCode(newValue)) {
                this._set("color", newValue);
                this.domNode.style.setProperty("--progress-color", newValue);
            } else {
                this._setColorEnum(newValue);
            }
        },

        _setColorEnum: function(value) {
            var val, progressColor;
            switch (value.toLowerCase()) {
                case "error":
                    val = "Error";
                    progressColor = "var(--progress-color-error)";
                    break;
                case "warning":
                    val = "Warning";
                    progressColor = "var(--progress-color-warning)";
                    break;
                case "success":
                    val = "Success";
                    progressColor = "var(--progress-color-success)";
                    break;
                default:
                    val = "Info";
                    progressColor = "var(--progress-color-info)";
                    break;
            }
            this._set("color", val);
            this.domNode.style.setProperty("--progress-color", progressColor);
        },

        _isHexColorCode: function(value) {
            return /^#([0-9A-F]{3}){1,2}$/i.test(value);
        }

    });

    return _progressMixin;
});
