//noinspection BadExpressionStatementJS,UnterminatedStatementJS
define({ root:

    ({
        ok: "OK",
        cancel: "Cancel",
        switchPanes: "Switch Windows",
        close: "Close",
        yes: "Yes",
        no: "No",
        help: "Help",
        submit: "Submit",
        save: "Save",
        find: "Find",
        create: "Create",
        dontSave: "Don't Save",
        apply: "Apply",
        revert: "Revert"
    }),

    "ja-jp": true,
    "ko-kr": true,
    "zh-cn": true
});
