import {objectDeepAssign} from '../utils/utils';

export default class AuthnzService {
  constructor (context, configuration, logger) {
    this._context = context;
    this._configuration = configuration;
    this._logger = logger;
    this._serviceName = 'core/authnz';
  }

  async listIdps () {
    let request = {
      mwtype: 'authnz/ListIdps'
    };
    let route = await this._getServiceRoute();
    let response = await this._context.dispatch(request, route);
    return response.idps;
  }

  async getIdp (email) {
    let request = {
      mwtype: 'authnz/GetIdp',
      email: email
    };
    let route = await this._getServiceRoute();
    let response = await this._context.dispatch(request, route);
    return response.idp;
  }

  async getLoginEndpoint (destUrl, options) {
    let request = {
      mwtype: 'authnz/GetLoginEndpoint',
      destUrl: destUrl || document.location.toString()
    };

    request = objectDeepAssign(request, options);

    let route = await this._getServiceRoute();
    let response = await this._context.dispatch(request, route);
    delete response.mwtype; // kind of extraneous since it's always a single type
    return response;
  }

  async contextTokenCheck () {
    let route = await this._getServiceRoute();
    let response = await this._context.dispatch('authnz/ContextTokenCheck', route);
    delete response.mwtype; // kind of extraneous since it's always a single type
    return response;
  }

  async passwordLogin (subjectId, password, options) {
    this._logger.debug('Logging in with subjectId', subjectId);
    let request = {
      mwtype: 'authnz/PasswordLogin',
      subjectId: subjectId,
      password: password
    };

    request = objectDeepAssign(request, options);

    let route = await this._getServiceRoute();
    let response = await this._context.dispatch(request, route);
    delete response.mwtype; // kind of extraneous since it's always a single type
    return response;
  }

  async codeLogin (code, options) {
    let request = {
      mwtype: 'authnz/CodeLogin',
      code: code
    };

    request = objectDeepAssign(request, options);

    let route = await this._getServiceRoute();
    let response = await this._context.dispatch(request, route);
    delete response.mwtype; // kind of extraneous since it's always a single type
    return response;
  }

  async tokenLogin (authToken, options) {
    let request = {
      mwtype: 'authnz/TokenLogin',
      authToken: authToken
    };

    request = objectDeepAssign(request, options);

    let route = await this._getServiceRoute();
    let response = await this._context.dispatch(request, route);
    delete response.mwtype; // kind of extraneous since it's always a single type
    return response;
  }

  async tokenLogout (authToken, options) {
    let request = {
      mwtype: 'authnz/TokenLogout',
      authToken: authToken
    };

    request = objectDeepAssign(request, options);

    let route = await this._getServiceRoute();
    let response = await this._context.dispatch(request, route);
    delete response.mwtype; // kind of extraneous since it's always a single type
    return response;
  }

  async awsSelectRole (role) {
    let request = {
      mwtype: 'authnz/AssumeSelectedAWSRole',
      role: role
    };
    let route = await this._getServiceRoute();
    let response = await this._context.dispatch(request, route);
    return response
  }

  async _getServiceRoute () {
    if (!this._serviceRoute) {
      this._serviceRoute = await this._context.lookupRoute(this._serviceName);
    }
    return this._serviceRoute;
  }

}
