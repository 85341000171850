 
  //noinspection BadExpressionStatementJS,UnterminatedStatementJS
 define({ 
         ok: "확인",
         cancel: "취소",
         switchPanes: "창 전환",
         close: "닫기",
         yes: "예",
         no: "아니요",
         help: "도움말",
         submit: "제출",
         save: "저장",
         find: "찾기",
         create: "만들기",
         dontSave: "저장 안 함",
         apply: "적용",
         revert: "되돌리기"
     });

