
function arrayDeepEquals (a, b) {
  if (a.length !== b.length) {
    return false;
  } else {
    for (let i = 0; i < a.length; i += 1) {
      if (!deepEquals(a, b)) {
        return false;
      }
    }
    return true;
  }
}

function objectDeepEquals (a, b) {
  let aKeys = Object.keys(a);
  let bKeys = Object.keys(b);
  if (aKeys.length !== bKeys.length) {
    return false;
  } else {
    aKeys.sort();
    bKeys.sort();
    for (let i = 0; i < aKeys.length; i += 1) {
      let key = aKeys[i];
      if (key !== bKeys[i] || !deepEquals(a[key], b[key])) {
        return false;
      }
    }
    return true;
  }
}

function deepEquals (a, b) {
  let aType = typeof a;
  if (a === b) {
    return true;
  } else if (aType !== typeof b) {
    return false;
  } else if (aType === 'string') {
    return a === b;
  } else if (aType === 'number') {
    return a === b;
  } else if (aType === 'boolean') {
    return a === b;
  } else if (a === null) {
    return false;
  } else if (a === undefined) {
    return false;
  } else if (Array.isArray(a)) {
    return arrayDeepEquals(a, b);
  } else if (aType === 'object') {
    return objectDeepEquals(a, b);
  } else {
    throw 'Unsupported type: ' + aType;
  }
}

function objectDeepAssign (target) {
  var to = Object(target);

  for (let index = 1; index < arguments.length; index += 1) {
    let nextSource = arguments[index];

    if (nextSource != null) { // Skip over if undefined or null
      for (let nextKey in nextSource) {
        // Avoid bugs when hasOwnProperty is shadowed
        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
          if (typeof nextSource[nextKey] === 'object') {
            to[nextKey] = objectDeepAssign(to[nextKey], nextSource[nextKey]);
          } else {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
    }
  }
  return to;
}

function parseQuery () {
  let queryString = document.location.search.substr(1);
  let parts = queryString.split('&');

  let query = {};
  parts.forEach(part => {
    if (part.length > 0) {
      let kv = part.split('=');
      if (kv.length === 1) {
        query[kv[0]] = true;
      } else if (kv.length === 2) {
        query[kv[0]] = kv[1];
      } else if (kv.length > 2) {
        query[kv[0]] = kv.slice(1);
      }
    }
  });

  return query;
}

function setLogLevel (mode, logger) {
    switch (mode) {
        case "dev":
           logger.threshold = 5;
           break;
        case "canary":
           logger.threshold = 3;
           break;
        default:
          logger.threshold = 2;
    }
}

export {
  deepEquals,
  arrayDeepEquals,
  objectDeepEquals,
  objectDeepAssign,
  parseQuery,
  setLogLevel
};
